define("discourse/plugins/discourse-encrypt/discourse/connectors/topic-above-post-stream/encrypt-topic-class", ["exports", "discourse/helpers/body-class", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _bodyClass, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @outletArgs.model.encrypted_title}}
      {{bodyClass "encrypted-topic-page"}}
    {{/if}}
  
  */
  {
    "id": "xVM7ae4V",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"encrypted_title\"]],[[[1,\"    \"],[1,[28,[32,0],[\"encrypted-topic-page\"],null]],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-encrypt/discourse/connectors/topic-above-post-stream/encrypt-topic-class.js",
    "scope": () => [_bodyClass.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "encrypt-topic-class"));
});