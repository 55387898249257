define("discourse/plugins/discourse-encrypt/discourse/initializers/encrypt-composer", ["exports", "@ember/application", "rsvp", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol"], function (_exports, _application, _rsvp, _pluginApi, _composer, _decorators, _I18n, _discourse, _protocol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "encrypt-composer",
    initialize(container) {
      const user = container.lookup("service:current-user");
      if ((0, _discourse.getEncryptionStatus)(user) !== _discourse.ENCRYPT_ACTIVE) {
        return;
      }

      // Register custom fields to be saved for new post.
      _composer.default.serializeOnCreate("is_encrypted", "isEncrypted");
      _composer.default.serializeOnCreate("delete_after_minutes", "deleteAfterMinutes");
      (0, _pluginApi.withPluginApi)("0.11.3", api => {
        // Check recipients and show encryption status in composer.
        api.modifyClass("model:composer", dt7948.p({
          pluginId: "encrypt-composer",
          updateEncryptProperties() {
            let isEncrypted = this.isEncrypted;
            if (this.topic && this.topic.encrypted_title && (0, _discourse.hasTopicKey)(this.topic.id)) {
              // Force encryption for existing encrypted topics.
              isEncrypted = true;
            } else if (this.isNew && this.creatingPrivateMessage) {
              // `isEncryptedChanged` is set true only when the value of
              // `isEncrypted` is changed. This is needed because during save
              // (serialization), this method is called and `isEncrypted` is
              // reset.
              if (!this.isEncryptedChanged) {
                isEncrypted = this.encryptPmsDefault;
              }
            }
            this.setProperties({
              /** @var Whether the current message is going to be encrypted. */
              isEncrypted,
              /** @var Whether current error is shown or not. In most cases, it
               *       is equal to `isEncrypted` except when `isEncrypted` is
               *       forcibly set to false (i.e. when an error occurs).
               */
              showEncryptError: isEncrypted
            });
          },
          get encryptPmsDefault() {
            if (!this.siteSettings.allow_new_encrypted_pms) {
              return false;
            }
            const currentUser = (0, _application.getOwner)(this).lookup("service:current-user");
            return currentUser.encrypt_pms_default;
          },
          checkEncryptRecipients() {
            if (!this.targetRecipients || this.targetRecipients.length === 0) {
              this.setProperties({
                isEncrypted: this.encryptPmsDefault,
                isEncryptedChanged: true,
                showEncryptError: this.encryptPmsDefault,
                encryptErrorUser: false,
                encryptErrorGroup: false
              });
              return;
            }
            const recipients = this.targetRecipients.split(",");
            recipients.push(this.user.username);
            const allGroupNames = new Set(this.site.groups.map(g => g.name.toLowerCase()));
            const groups = recipients.filter(r => allGroupNames.has(r.toLowerCase()));
            if (groups.length > 0) {
              this.setProperties({
                isEncrypted: false,
                isEncryptedChanged: true,
                showEncryptError: this.showEncryptError || this.isEncrypted,
                encryptErrorGroup: true
              });
            } else {
              this.setProperties({
                encryptErrorGroup: false
              });
            }
            const usernames = recipients.filter(r => !allGroupNames.has(r.toLowerCase()));
            (0, _discourse.getUserIdentities)(usernames).then(() => {
              this.setProperties({
                encryptErrorUser: false
              });
            }).catch(username => {
              this.setProperties({
                isEncrypted: false,
                isEncryptedChanged: true,
                showEncryptError: this.showEncryptError || this.isEncrypted,
                encryptErrorUser: username
              });
            });
          },
          encryptErrorMissingKey(encryptedTitle, topicId) {
            return encryptedTitle && !(0, _discourse.hasTopicKey)(topicId);
          },
          encryptError(missingKey, username, group) {
            if (missingKey) {
              return _I18n.default.t("encrypt.composer.no_topic_key");
            } else if (!this.siteSettings.allow_new_encrypted_pms && this.isNew && this.creatingPrivateMessage) {
              return _I18n.default.t("encrypt.composer.new_encrypted_pms_disabled");
            } else if (username) {
              return _I18n.default.t("encrypt.composer.user_has_no_key", {
                username
              });
            } else if (group) {
              return _I18n.default.t("encrypt.composer.group_not_allowed");
            }
          },
          beforeSave() {
            if (!this.showEncryptError || !this.encryptError) {
              return _rsvp.Promise.resolve();
            }
            return new _rsvp.Promise((resolve, reject) => {
              (0, _application.getOwner)(this).lookup("service:dialog").yesNoConfirm({
                message: _I18n.default.t("encrypt.composer.confirm.message", {
                  error: this.encryptError
                }),
                didConfirm: () => resolve(),
                didCancel: () => reject()
              });
            });
          }
        }, [["method", "updateEncryptProperties", [(0, _decorators.observes)("creatingPrivateMessage", "topic"), (0, _decorators.on)("init")]], ["method", "checkEncryptRecipients", [(0, _decorators.observes)("targetRecipients")]], ["method", "encryptErrorMissingKey", [(0, _decorators.default)("topic.encrypted_title", "topic.id")]], ["method", "encryptError", [(0, _decorators.default)("encryptErrorMissingKey", "encryptErrorUser", "encryptErrorGroup", "siteSettings.allow_new_encrypted_pms", "isNew", "creatingPrivateMessage")]]]));
      });

      // Decode composer on reply reload. This usually occurs when a post is
      // edited or a draft is loaded.
      const appEvents = container.lookup("service:app-events");
      appEvents.on("composer:reply-reloaded", this, this.composerReplyReloaded);
    },
    composerReplyReloaded(model) {
      if (!model.privateMessage) {
        return;
      }
      let decTitle, decReply;
      if (model.action === "edit" && model.originalText) {
        const topicId = model.get("topic.id");
        if (!(0, _discourse.hasTopicKey)(topicId)) {
          return;
        }
        decTitle = (0, _discourse.getTopicTitle)(topicId);
        decReply = (0, _discourse.getTopicKey)(topicId).then(key => (0, _protocol.decrypt)(key, model.reply)).then(decrypted => decrypted.raw);
      } else {
        const pos = model.reply ? model.reply.indexOf("\n") : -1;
        if (pos === -1) {
          return;
        }
        const topicKey = model.reply.substr(0, pos).trim();
        const reply = model.reply.substr(pos + 1).trim();
        const decKey = (0, _discourse.getIdentity)().then(identity => (0, _protocol.importKey)(topicKey, identity.encryptPrivate));
        if (model.title) {
          decTitle = decKey.then(key => (0, _protocol.decrypt)(key, model.title));
        }
        if (reply) {
          decReply = decKey.then(key => (0, _protocol.decrypt)(key, reply)).then(decrypted => decrypted.raw);
        }
      }
      if (decTitle) {
        decTitle.then(title => model.setProperties({
          title,
          isEncrypted: true,
          isEncryptedChanged: true
        }));
      }
      if (decReply) {
        decReply.then(reply => model.setProperties({
          reply,
          isEncrypted: true,
          isEncryptedChanged: true
        }));
      }
    }
  };
});