define("discourse/plugins/discourse-encrypt/discourse/api-initializers/encrypt-model-transformers", ["exports", "discourse/lib/api", "discourse/lib/utilities", "discourse/plugins/discourse-encrypt/lib/discourse"], function (_exports, _api, _utilities, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const currentUser = api.getCurrentUser();
    if ((0, _discourse.getEncryptionStatus)(currentUser) !== _discourse.ENCRYPT_ACTIVE) {
      // No point adding these transforms if we can't actually decrypt
      return;
    }
    api.registerModelTransformer("topic", async topics => {
      for (const topic of topics) {
        if (topic.topic_key && topic.encrypted_title) {
          (0, _discourse.putTopicKey)(topic.id, topic.topic_key);
          (0, _discourse.putTopicTitle)(topic.id, topic.encrypted_title);
          try {
            const decryptedTitle = await (0, _discourse.getTopicTitle)(topic.id);
            if (decryptedTitle) {
              topic.set("fancy_title", (0, _utilities.escapeExpression)(decryptedTitle));
              topic.set("unicode_title", decryptedTitle);
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(`Decrypting the title of encrypted message (topicId: ${topic.id}) failed with the following error:`, err, err?.stack);
          }
        }
      }
    });
    api.registerModelTransformer("bookmark", async bookmarks => {
      for (const bookmark of bookmarks) {
        if (bookmark.topic_id && bookmark.topic_key && bookmark.encrypted_title) {
          (0, _discourse.putTopicKey)(bookmark.topic_id, bookmark.topic_key);
          (0, _discourse.putTopicTitle)(bookmark.topic_id, bookmark.encrypted_title);
          try {
            const decryptedTitle = await (0, _discourse.getTopicTitle)(bookmark.topic_id);
            if (decryptedTitle) {
              bookmark.title = decryptedTitle;
              bookmark.fancy_title = (0, _utilities.escapeExpression)(decryptedTitle);
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(`Decrypting the title of encrypted message (topicId: ${bookmark.topic_id}) failed with the following error:`, err, err?.stack);
          }
        }
      }
    });
    api.registerModelTransformer("notification", async notifications => {
      for (const notification of notifications) {
        if (notification.topic_id && notification.topic_key && notification.encrypted_title) {
          (0, _discourse.putTopicKey)(notification.topic_id, notification.topic_key);
          (0, _discourse.putTopicTitle)(notification.topic_id, notification.encrypted_title);
          try {
            const decryptedTitle = await (0, _discourse.getTopicTitle)(notification.topic_id);
            if (decryptedTitle) {
              notification.fancy_title = (0, _utilities.escapeExpression)(decryptedTitle);
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(`Decrypting the title of encrypted message (topicId: ${notification.topic_id}) failed with the following error:`, err, err?.stack);
          }
        }
      }
    });
  });
});