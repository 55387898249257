define("discourse/plugins/discourse-encrypt/discourse/api-initializers/encrypt-delete-topic", ["exports", "@ember/application", "@ember/object", "discourse/lib/ajax", "discourse/lib/api", "discourse/models/post", "I18n"], function (_exports, _application, _object, _ajax, _api, _post, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    api.modifyClass("controller:topic", Superclass => class extends Superclass {
      permanentDeleteConfirmation(callback) {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.deleteConfirm({
          title: _I18n.default.t("encrypt.post.delete.title"),
          message: _I18n.default.t("encrypt.post.delete.confirm"),
          didConfirm: () => callback()
        });
      }
      createTimer(post_id) {
        return (0, _ajax.ajax)("/encrypt/encrypted_post_timers", {
          type: "POST",
          data: {
            post_id
          }
        });
      }
      deleteTimer(post_id) {
        return (0, _ajax.ajax)("/encrypt/encrypted_post_timers", {
          type: "DELETE",
          data: {
            post_id
          }
        });
      }
      deleteTopic() {
        if (this.model.encrypted_title) {
          this.permanentDeleteConfirmation(() => {
            return this.createTimer(this.model.postStream.posts[0].id).then(() => this.model.destroy(this.currentUser));
          });
        } else {
          return super.deleteTopic(...arguments);
        }
      }
      static #_ = (() => dt7948.n(this.prototype, "deleteTopic", [_object.action]))();
      deletePost(post) {
        if (post.encrypted_raw && post.get("post_number") !== 1) {
          this.permanentDeleteConfirmation(() => {
            return this.createTimer(post.id).then(result => {
              post.setProperties({
                delete_at: result.delete_at
              });
              return super.deletePost(...arguments);
            });
          });
        } else {
          return super.deletePost(...arguments);
        }
      }
      static #_2 = (() => dt7948.n(this.prototype, "deletePost", [_object.action]))();
      deleteSelected() {
        const user = this.currentUser;
        if (this.selectedAllPosts) {
          this.send("toggleMultiSelect");
          return this.deleteTopic();
        }
        if (this.selectedPosts[0].encrypted_raw) {
          this.permanentDeleteConfirmation(() => {
            return this.createTimer(this.selectedPostIds).then(result => {
              _post.default.deleteMany(this.selectedPostIds);
              this.get("model.postStream.posts").forEach(p => {
                this.postSelected(p) && p.setDeletedState(user) && p.setProperties({
                  delete_at: result.delete_at,
                  deleted_at: new Date(),
                  deleted_by: user
                });
              });
              this.send("toggleMultiSelect");
            });
          });
        } else {
          return super.deleteSelected(...arguments);
        }
      }
      static #_3 = (() => dt7948.n(this.prototype, "deleteSelected", [_object.action]))();
      recoverTopic() {
        if (this.model.encrypted_title) {
          return this.deleteTimer(this.model.postStream.posts[0].id).then(() => {
            this.model.postStream.posts[0].setProperties({
              delete_at: false
            });
            return super.recoverTopic(...arguments);
          });
        } else {
          return super.recoverTopic(...arguments);
        }
      }
      static #_4 = (() => dt7948.n(this.prototype, "recoverTopic", [_object.action]))();
      recoverPost(post) {
        if (post.encrypted_raw) {
          return this.deleteTimer(post.id).then(() => {
            post.setProperties({
              delete_at: false
            });
            return super.recoverPost(...arguments);
          });
        } else {
          return super.recoverPost(...arguments);
        }
      }
      static #_5 = (() => dt7948.n(this.prototype, "recoverPost", [_object.action]))();
    });
  });
});